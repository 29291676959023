<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a href="/dashboard" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo-light.svg" alt="" height="30">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo-light.svg" alt="" height="30">
                    </span>
                </a>

                <a href="/dashboard" class="logo logo-light">
                    <span class="logo-sm" style="margin-top: 15px;">
                        <img src="assets/images/logo-light.svg" alt="" height="30">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo-light.svg" alt="" height="30">
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
                (click)="toggleMobileMenu($event)">
                <i class="fa fa-fw fa-bars"></i>
            </button>

            <!-- App Search-->


        </div>
        <span class="font-size-4">Versión: {{version}}</span>
        <div class="d-flex">


            
            <div class="dropdown d-inline-block" ngbDropdown
                *ngIf="dtoServicios.cpresupuesto!=null && dtoServicios.estado">
                <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                    <span class="ml-1">AÑO {{dtoServicios.cpresupuesto}}</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

                    <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listMenu"
                        (click)="setMenu(item.text, item.op,item.tr)" [ngClass]="{'active': cookieValue === item.op}">
                        <span class="align-middle">{{item.text}}</span>
                    </a>

                </div>
            </div>


            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
                    <i class="bx bx-customize"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" ngbDropdownMenu>
                    <div class="px-lg-2">
                        <div class="row no-gutters">

                            <div class="col" *ngFor="let  rol of appService.lrolesusuario">

                                <a class="dropdown-icon-item" (click)="cambiarRol(rol);">
                                    <!--<img src="assets/images/brands/github.png" alt="Github">-->
                                    <i class="bx bx-layer"></i>
                                    <span>{{rol.label}}</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
                    (click)="borrar()" ngbDropdownToggle>
                    <i class="bx bx-bell bx-tada"></i>

                    <span *ngIf="this.signalRService.ndata>0"
                        class="badge badge-danger badge-pill">{{this.signalRService.ndata}}</span>

                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                    aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
                            </div>

                        </div>
                    </div>
                    <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData"
                        (psYReachEnd)="onReachEnd()">
                        <ul class="list-unstyled chat-list">
                            <li class="" *ngFor="let data of this.signalRService.data">
                                <a href="javascript: void(0);" (click)="notificacionVisitada(data)"
                                    [ngClass]="{'sinver':data.flectura === null}">
                                    <div class="media">
                                        <img src="{{(data.mdatos.foto)?data.mdatos.foto:'assets/images/users/avatar.png'}}"
                                            class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                                        <div class="media-body">
                                            <h6 class="mt-0 mb-1"> {{ data.titulo}}</h6>
                                            <div class="font-size-10 text-muted">
                                                <p class="mb-1">{{ data.mensaje}}</p>
                                                <p class="mb-0"><i *ngIf="!data.flectura"
                                                        class="mdi mdi-circle notificacion font-size-10"></i>
                                                    <i *ngIf="data.flectura" class="mdi mdi-clock-outline"></i> {{' '}}
                                                    {{
                                                    getMinimalisticRelativeTime(data.fingreso)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </perfect-scrollbar>
                </div>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
                    <img class="rounded-circle header-profile-user"
                        src="{{(this.dtoServicios.mradicacion.foto)?this.dtoServicios.mradicacion.foto:'assets/images/users/avatar.png'}}"
                        alt="Header Avatar">
                    <span class="d-none d-xl-inline-block ml-1">{{this.dtoServicios.mradicacion.np}}</span>
                    
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <!-- item-->
                    <a class="dropdown-item" href="javascript: void(0);" (click)="cargaPerfil()"><i
                            class="bx bx-user font-size-16 align-middle mr-1"></i>
                        {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="cargarCambio()"><i
                            class="bx bx-shield-alt-2 font-size-16 align-middle mr-1"></i>
                        Cambiar clave</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                            class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</header>