import { Injectable } from '@angular/core';
import interact from 'interactjs';

@Injectable({
  providedIn: 'root',
})
export class InteractService {
  public coordinates = {
    x: null,
    y: null,
  };

  constructor() { }

  public init() {
    interact('.dropzone').dropzone({
      // only accept elements matching this CSS selector
      accept: '.drag-drop',
      // Require a 75% element overlap for a drop to be possible
      overlap: 0.75,

      // listen for drop related events:

      ondropactivate: function (event) {
        // add active dropzone feedback
        event.target.classList.add('drop-active');
      },
      ondragenter: function (event) {
        var draggableElement = event.relatedTarget;
        var dropzoneElement = event.target;

        // feedback the possibility of a drop
        dropzoneElement.classList.add('drop-target');
        draggableElement.classList.add('can-drop');
        draggableElement.style.backgroundsColor = "red";
        draggableElement.style.backgroundRepeat = 'no-repeat';
        draggableElement.style.border = 'thin dotted red';
        draggableElement.style.width = '20px';
        draggableElement.style.height = '2px';
        draggableElement.textContent = '';
      },
      ondragleave: function (event) {
        // remove the drop feedback style
        event.target.classList.remove('drop-target');
        event.relatedTarget.classList.remove('can-drop');
        event.relatedTarget.textContent = 'Elige otro lugar';
      },
      ondropdeactivate: function (event) {
        // remove active dropzone feedback
        event.target.classList.remove('drop-active');
        event.target.classList.remove('drop-target');
      },
    });

    interact('.drag-drop').draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrict({
          restriction: 'parent',
          endOnly: true,
        }),
      ],
      autoScroll: true,
      listeners: {
        move: (event) => {
          const target = event.target;
          // keep the dragged position in the data-x/data-y attributes
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

          // translate the element
          target.style.webkitTransform = target.style.transform =
            'translate(' + x + 'px, ' + y + 'px)';

          // update the posiion attributes
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
          const ptX = x;
          const ptY = y;
        },
        // call this function on every dragend event
        end: (e) => {
          const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
            document.getElementById('pdfCanvas')
          );
          const rect = canvas.getBoundingClientRect();

          const x = e.client.x - rect.left;
          const y = e.client.y - rect.top;

          const ptX = (x+8);
          const ptY = (y+8);

          this.coordinates = { x: ptX, y: ptY };
          console.log("ccordenadas",rect.left, rect.top);
          console.log("x y y",ptX, ptY);
        },
      },
    });
  }

  getCoordinates() {
    return this.coordinates;
  }
}
