import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: "root",
})
export class ExcelReportService {
  generateDefaultReport(data: any[], filename: string,title:string) {
    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(data);

    // first way to add a sheet
    workbook.SheetNames.push(title);
    workbook.Sheets[title] = worksheet;

    // second way to add a sheet
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja 1")

    XLSX.writeFileXLSX(workbook, filename, {});
  }
  generateReportWithDict(customHeaders: any[], data: any[], filename: string) {
    let worksheetData: any[] = [];

    Object(data).forEach( (item: any) => {
      let worksheetItem = Object();
      customHeaders.forEach( header => {
        worksheetItem[header.name] = item[header.key];
      })
      worksheetData.push(worksheetItem)
    })

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja 1");
    XLSX.writeFileXLSX(workbook, filename, {});
  }
  generateReportWithAdapter(headers: string[], data: any[], filename: string) {
    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet([], { header: headers });
    XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja 1")
    XLSX.writeFileXLSX(workbook, filename);
  }
}
