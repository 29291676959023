import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/map';
import { map } from "rxjs/operators";
import 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Routes, Router, ActivatedRoute } from '@angular/router';
//Libreria para protejer apicore
import { environment } from '../../../environments/environment';

import { LoadingBarService } from '@ngx-loading-bar/core';
//Libreria para notificaciones
import { NotifierService } from 'angular-notifier';

import Swal from 'sweetalert2';

import { Observable } from 'rxjs/Rx';
@Injectable()
export class Radicacion {
  public ccompania: number;
  public csucursal: number;
  public cagencia: number;
  public cusuario: string;
  public ccanal: string;
  public ncompania: string;
  public foto:string;
  public nsucursal: string;
  public nagencia: string;
  public timeoutminutos: number;
  public timeoutsegundos: number;
  public fcontable: number;
  public cpersona: number;
  public np: string;
  public ftrabajo: string;
  

  constructor(@Inject(Number) ccompania: number,   @Inject(String) cusuario: string, @Inject(String) ccanal: string, @Inject(String) ncompania: string,@Inject(String) foto: string) {
    this.ccompania = ccompania;
    this.cusuario = cusuario;
    this.ccanal = ccanal;
    this.ncompania = ncompania;
    this.foto=foto;
  }
}
@Injectable()
export class Localizacion {
  public cpais:number;
  public abreviatura:string;
  public cregion: string;
  public nombre: string;
  public bandera: string;
  public preciokm: number;
  public moneda: string;
  public simbolo: string;
  public impuesto: number;
  public comision: number;
  public distancia:number;
  public preciobase:number;
  constructor(@Inject(Number) cpais: number,   @Inject(String) abreviatura: string, @Inject(String) cregion: string, 
  @Inject(String) nombre: string,@Inject(String) bandera: string
  ,@Inject(Number) preciokm: number,@Inject(String) moneda: string,@Inject(String) simbolo: string,@Inject(Number) impuesto: number,@Inject(Number) comision: number,@Inject(Number) distancia: number,@Inject(Number) preciobase: number,) {
    this.cpais = cpais;
    this.abreviatura = abreviatura;
    this.cregion = cregion;
    this.nombre = nombre;
    this.bandera = bandera;
    this.preciokm = preciokm;
    this.moneda = moneda;
    this.simbolo = simbolo;
    this.impuesto = impuesto;
    this.comision = comision;
    this.distancia=distancia;
    this.preciobase=preciobase;
  }
}
@Injectable()
export class DtoServicios {
  config: any = { 'host': environment.apiDomain, 'port': environment.apiPort, 'context': '/api' };
  //config: any = { 'host': 'http://localhost', 'port': '65510', 'context': '/api' };

  private notifier: NotifierService;
  public urlConsultar = '';
  public urlMantener = '';
  public urlLogin = '';
  public idConnection:String='';
  /**Objeto que gurada datos temporales utiiados en la transaccion. */
  public mradicacion: Radicacion=null;
  public mlocalizacion: Localizacion;
  

  public ipreal = ' ';


  /**Objeto que contine mensajes aplicativos. */
  //msgs: MatSnackBar;

  /**Variables de timeout. */
  public minutos: number;
  public segundos: number;
  public intervalID: any;
  public logeado = false;
  public loading=false;
  public identificador:String='';

  public cpresupuesto:number=null;
  public estado:boolean=false;

  constructor(private http: HttpClient, private router: Router, public loadingService: LoadingBarService, notifier: NotifierService) {
    this.urlLogin = this.config.host + ':' + this.config.port + '' + this.config.context + '/login';
    this.timeout();
    this.identificador=Math.ceil(Math.random())+"";
    this.notifier = notifier;
  }
  timer: number = 0;
  private timeout() {
    this.resetTimer();
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  encrypt(text:any,pass:any){

    return text+ pass;
}
  private tick(): void {
    if (--this.segundos < 0) {
      this.segundos = 59;
      if (--this.minutos < 0) {
        this.logout()
      }
    }
  }
  public estaVacio(obj: any): boolean {
    if (obj === undefined || obj === null || obj === '' || (typeof obj === 'object' && !(obj instanceof Date) && Object.keys(obj).length === 0) || obj.length <= 0) {
      return true;
    }
    return false;
  }
  start() {
    this.loadingService.start();
    this.loading=true;
  }

  complete() {
    this.loadingService.complete();
    this.loading=false;
  }
  startTimer() {
    const timer$ = Observable
      .interval(1000)
      .take(3);

    timer$.subscribe(
      (value) => this.timer = value + 1,
      (err) => this.loadingService.complete(),
      () => this.loadingService.complete(),
    );

    // We're sure that subscription has been made, we can start loading bar service
    this.loadingService.start();
  }
  private resetTimer(): void {
    clearInterval(this.intervalID);
    if (this.logeado) {
      this.minutos = this.mradicacion.timeoutminutos;
      this.segundos = this.mradicacion.timeoutsegundos;
    }
    else {
      this.minutos = 14;
      this.segundos = 59;
    }
  }

  private setUrlMantener(appName: any, metodo: any) {
    this.urlMantener = '';
    let puerto =(this.config.port.length>0)?":":"";
    if (appName == null && metodo == null) {
      this.urlMantener =  this.config.host + puerto + this.config.port + '' + this.config.context + '/m';
    }
    if (appName == null && metodo != null) {
      this.urlMantener =  this.config.host +puerto + this.config.port + '' + this.config.context + '/' + metodo;
    }
    if (appName != null && metodo == null) {
      this.urlMantener = this.config.host + puerto+ this.config.port + '/' + appName + this.config.context + '/m';
    }
    if (appName != null && metodo != null) {
      this.urlMantener =  this.config.host + puerto + this.config.port + '/' + appName + this.config.context + '/' + metodo;
    }
 }

  private setUrlConsultar(appName: any, metodo: any) {
    let puerto =(this.config.port.length>0)?":":"";
    this.urlConsultar = '';
    if (appName == null && metodo == null) {
      this.urlConsultar = this.urlConsultar + this.config.host + puerto + this.config.port + this.config.context + '/c';
    }
    if (appName == null && metodo != null) {
      this.urlConsultar = this.urlConsultar + this.config.host +puerto +  this.config.port + this.config.context + '/' + metodo;
    }
    if (appName != null && metodo == null) {
      this.urlConsultar = this.urlConsultar + this.config.host + puerto + this.config.port +  '/' + appName + this.config.context + '/c';
    }
    if (appName != null && metodo != null) {
      this.urlConsultar = this.urlConsultar + this.config.host + puerto + this.config.port + '/' + appName + this.config.context + '/' + metodo;
    }
    
  }

  public dateParser(key, value) {
    const reISOFull = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})?$/;

    if (typeof value === 'string' && value.indexOf(':00:00') > 0) {
      //const a = reISOFull.exec(value);
      if (reISOFull.exec(value) || reISO.exec(value)) {
        const anio = value.substr(0, 4);
        const mes = value.substr(5, 2);
        const dia = value.substr(8, 2);
        return anio + '-' + mes + '-' + dia ;
      }
    }
    return value;
  };

  public dateString(key, value) {
    const reISOFull = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})?$/;
    if (typeof value === 'string') {
      //const a = reISOFull.exec(value);
      if (reISOFull.exec(value) || reISO.exec(value)) {
        const f = new Date(value);
        const curr_date = f.getDate();
        const curr_month = f.getMonth() + 1;
        const curr_year = f.getFullYear();
        let monthstr = '' + curr_month;
        let daystr = '' + curr_date;
        if (curr_month < 10) { monthstr = '0' + curr_month; }
        if (curr_date < 10) { daystr = '0' + curr_date; }
        return curr_year + '-' + monthstr + '-' + daystr + ' 00:00:00';
      }
    }
    return value;
  };

  public obtenerIpLocal() {
    this.consultarIpLocal();
  }

  public consultarIpLocal() {
    const win: any = window;
    win.RTCPeerConnection = win.RTCPeerConnection || win.mozRTCPeerConnection || win.webkitRTCPeerConnection;
    if (win.RTCPeerConnection === undefined) {
      return;
    }
    const pc: any = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
    try {
      pc.createDataChannel('');
      pc.createOffer(pc.setLocalDescription.bind(pc), noop);    // create offer and set local description
      pc.onicecandidate = this.registraIp;
    } catch (e) { }
  }

  registraIp = (ice) => {
    if (!ice || !ice.candidate || !ice.candidate.candidate) { return };
    const resp = /([0-9]{1,3}(\.[0-9]{1,3}){3})/.exec(ice.candidate.candidate);
    if (resp != null && resp.length > 0) {
      this.ipreal = resp[0];
    }
  };

  /**Llamar al servicio rest de consulta */
  public ejecutarConsultaRest(rqConsulta: Object, appName: any = null, metodo: any = null) {
    rqConsulta['c'] = this.getCabecera(rqConsulta);
    if (this.estaVacio(rqConsulta['c'])) {
      return;
    }
    const rq = JSON.stringify(rqConsulta, this.dateString);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('x-auth-token', sessionStorage.getItem('jwt'));
    this.setUrlConsultar(appName, metodo);
    this.start();
    return  this.http.post(this.urlConsultar, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })

      //return this.http.post(this.urlConsultar, rq, {headers : new HttpHeaders({ 'Content-Type': 'application/json' }),withCredentials:true})

      .map((resp: Response) => {
         const data = JSON.stringify(resp);
        let r =JSON.parse(data,this.dateParser);
      
        this.complete()
        this.timeout();
        return r;
      })
  }
  llenarjwt(resp: any) {

    sessionStorage.setItem('jwt', resp.jwt);
  }
  /**Manejo de respuesta de la base de datos cuando existe un error */
  private handleError(error: any) {
    return Promise.reject(error.message || error);
  }


  /**Llamar al servicio rest de consulta */
  ejecutarRestMantenimiento(rqMan: Object, appName: any = null, metodo: any = null) {
    rqMan['c'] = this.getCabecera(rqMan);
    const rq = JSON.stringify(rqMan, this.dateString);
    const headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/json');
    //headers.append('x-auth-token', sessionStorage.getItem('jwt'));
    this.setUrlMantener(appName, metodo);
    this.start();

    return this.http.post(this.urlMantener, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .map((resp: Response) => {
        const r = resp;
        //sessionStorage.setItem('jwt', resp.headers['_headers'].get('x-auth-token'));
        this.complete();
        this.timeout();
        return r;
      });
  }

  ejecutarLogin(usuario: string, password: string) {
    let puerto = (this.config.port.length > 0) ? ":" + this.config.port : "";
    this.urlLogin = this.config.host + puerto + this.config.context + '/login' + "/login";

    this.urlLogin;
    const rqLogin = this.getRequestLogin(usuario, password,'TCL');
    const rq = JSON.stringify(rqLogin);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.start();

    return this.http.post(this.urlLogin, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' })})
      .map((resp: Response) => {
        let r: any;
        r = resp;
        this.complete();

        if (r.user && r.token) {
          r.user.token = r.token;
          r.user.password = null;
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(r.user));
        }
        this.timeout();
        return r;
      });
  }

  ejecutarCambioContrasenia(usuario: string, password: string) {
    let puerto = (this.config.port.length > 0) ? ":" + this.config.port : "";
    this.urlLogin = this.config.host + puerto + this.config.context + '/login' + "/changepassword";

    this.urlLogin;
    const rqLogin = this.getRequestLogin(usuario, password,'TCL');
    const rq = {
      "cusuario":usuario,
      "newPassword": password,
      "ccanal":"TCL"
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.start();

    return this.http.post(this.urlLogin, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .map((resp: Response) => {
        let r: any;
        r = resp;
        this.complete();
        return r;
      });
  }
  ejecutarResetPassword(usuario: string) {
    let puerto = (this.config.port.length > 0) ? ":" + this.config.port : "";
    this.urlLogin = this.config.host + puerto + this.config.context + '/login' + "/resetpassword";

    this.urlLogin;
    const rq = {
      "cusuario":usuario,
      "ccanal":"TCL"
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.start();

    return this.http.post(this.urlLogin, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .map((resp: Response) => {
        let r: any;
        r = resp;
        this.complete();
        return r;
      });
  }

  ejecutarRegistro(datos: any) {
    let puerto = (this.config.port.length > 0) ? ":" + this.config.port : "";
    let urlRegistro = this.config.host + puerto + this.config.context + '/login' + "/signup";

    this.urlLogin;
    datos.ccanal='TCL';
    const rqLogin = datos;
    const rq = JSON.stringify(rqLogin);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.start();
    return this.http.post(urlRegistro, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' })})
      .map((resp: Response) => {
        let r: any;
        r = resp;
        this.complete();
        this.timeout();
        return r;
      });
  }

  ejecutarServico(datos: any, ruta :any) {
    let puerto = (this.config.port.length > 0) ? ":" + this.config.port : "";
    let urlServicio = this.config.host + puerto + this.config.context + '/login' + "/"+ ruta;

    this.urlLogin;
    datos.ccanal='TCL';
    const rqLogin = datos;
    const rq = JSON.stringify(rqLogin);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.start();
    return this.http.post(urlServicio, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' })})
      .map((resp: Response) => {
        let r: any;
        r = resp;
        this.complete();
        this.timeout();
        return r;
      });
  }
  ejecutarServicoGenerico(datos: any, ruta :any) {
    let puerto = (this.config.port.length > 0) ? ":" + this.config.port : "";
    let urlServicio = this.config.host + puerto + this.config.context + ruta;

    this.urlLogin;
    datos.ccanal='TCL';
  
    const rqLogin = datos;
    const rq = JSON.stringify(rqLogin);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.start();
    return this.http.post(urlServicio, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' })})
      .map((resp: Response) => {
        let r: any;
        r = resp;
        this.complete();
        this.timeout();
        return r;
      });
  }
  ejecutarServicoNotificacion(datos: any, ruta :any) {
    let puerto = (this.config.port.length > 0) ? ":" + this.config.port : "";
    let urlServicio = this.config.host + puerto + this.config.context + ruta;

    this.urlLogin;
    datos.ccanal='TCL';
  
    const rqLogin = datos;
    const rq = JSON.stringify(rqLogin);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');    
    return this.http.post(urlServicio, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' })})
      .map((resp: Response) => {
        let r: any;
        r = resp;       
        this.timeout();
        return r;
      });
  }


  /**Llamar al servicio rest */

  /**Llamar al servicio rest de consulta */
  ejecutarRecuperarContrasenia(identificacion: string, canal: string, transaccion: string) {
    const rqLogin = this.getRequestLogin(identificacion, identificacion,'TCL');
    const rq = JSON.stringify(rqLogin);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.setUrlMantener('', 'mantener');
    this.start();
    return this.http.post(this.urlMantener, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .pipe(map(resp => {
        const r = resp;//.json();
        //  sessionStorage.setItem('jwt', resp.headers['_headers'].get('x-auth-token'));
        this.complete();
        this.timeout();
        return r;
      }));
  }

  private getRequestLogin(_usuario: string, _password: string, _ccanal:string) {
    let rqLogin = { cusuario: _usuario, password: _password, ccanal:_ccanal}
    return rqLogin;
  }
  
  private getCabecera(request: Object): string {
    this.obtenerIpLocal();

    let r: string = sessionStorage.getItem('c');
    
    if (request.hasOwnProperty('cmodulo') && request['cmodulo'] !== undefined) {
      r = r + '^' + this.ipreal + '^' + request['cmodulo'] + '^' + request['ctransaccion'];
    } else {
      r = r + '^' + this.ipreal + '^' + sessionStorage.getItem('m') + '^' + sessionStorage.getItem('t');
    }
    return r;
  }



  logout(msg = '') {

    this.encerarCredencialesLogin();    
    this.router.navigate(["/"]);
    
    location.reload();
/*
    const rqLogin = new Object();
    rqLogin['logoutuser'] = true;
    rqLogin['valida-usuario-login'] = false;
    rqLogin['cmodulo'] = '2';
    rqLogin['ctransaccion'] = '1001';

    const rq = JSON.stringify(rqLogin);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.ejecutarRestMantenimiento(rqLogin, '', 'logout').subscribe(
      resp => {
        this.llenarMensaje(resp, true); // solo presenta errores.
        // encerar token eliminar datos de radicacion del usuario.
        this.encerarCredencialesLogin();
        location.reload();
      },
      error => {
        this.manejoError(error);
      }
    );
  */
  }

  public encerarCredencialesLogin() {
    sessionStorage.removeItem('jwt');
    sessionStorage.clear();
  }

  // Metodo que inicializa los datos cuando un usuario se autentica exitosamente
  actualizarRadicacion(mradicacion: any): void {
    this.mradicacion = new Radicacion(mradicacion.cc,  mradicacion.cu, mradicacion.cca, mradicacion.nc,mradicacion.foto);
    this.mradicacion.timeoutminutos = mradicacion.timeoutminutos;
    this.mradicacion.timeoutsegundos = mradicacion.timeoutsegundos;
    this.mradicacion.cusuario = mradicacion.cu;
    this.mradicacion.np = mradicacion.np;
    this.mradicacion.foto=mradicacion.foto;
    this.logeado = true;
  }
  actualizarLocalizacion(mlocalizacion: any): void {
    this.mlocalizacion = new Localizacion(mlocalizacion.cpais,  mlocalizacion.abreviatura,mlocalizacion.cregion,mlocalizacion.nombre,mlocalizacion.bandera,mlocalizacion.preciokm,mlocalizacion.moneda,mlocalizacion.simbolo,mlocalizacion.impuesto,mlocalizacion.comision,mlocalizacion.distancia,mlocalizacion.preciobase
      );
  }

  abrirMensaje(message: string, action: string) {
    this.notifier.notify( 'success', message );

  }
  // OTROS ********************************************************
  public llenarMensaje(resp: any, muestaexito: boolean, limpiamsg = true) {
    this.loading=false;
    if (limpiamsg) {

    }
    if (resp.cod === 'OK') {
      if (muestaexito) {
        //this.msgs.open({ action: 'OK', message: 'TRANSACCIÓN FINALIZADA CORRECTAMENTE'});
        this.mostrarMensaje('TRANSACCIÓN FINALIZADA CORRECTAMENTE');
      }
      //      else {
      //        this.msgs = [];
      //      }
    } else if (resp.cod === 'F-000' || resp.cod === 'BGEN-021' || resp.cod === 'SEG-031') {
      // Sesion expirada, realizar logout
      // this.msgs = [];
      let msg = '';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      //this.msgs.push({ severity: 'error', summary: msg, detail: '' });
      //ERROR

      this.logout(msg);
      this.mostrarError(msg);
      //this.alertService.mostrarAlerta(msg, true);
    } else if (resp.cod === '000') {
      let msg = '';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      //this.msgs.push({ severity: 'warn', summary: msg, detail: '' });
      //ADVERTENCIA
      this.mostrarError(msg);
    } else {
      // this.msgs = [];
      let msg = '';
      msg = resp.cod !== undefined ? msg = msg + resp.cod.startsWith('javax') ? '' : resp.cod + ' ' : msg + ' ';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      //this.msgs.push({ severity: 'error', summary: msg, detail: '' });
      this.mostrarError(msg);
    }

    // this.alertService.mensaje(this.msgs);
  }

  /**Fija mensaje de respuesta resultado de ejecucion de una peticion al core. */
  public manejoError(resp: any) {
    if (resp !== null && resp.headers !== undefined) {
      //sessionStorage.setItem('jwt', resp.headers['_headers'].get('x-auth-token'));
    }
    let mensaje = 'ERROR DE CONEXIÓN CON EL SERVIDOR';
    if (resp.message !== undefined && resp.message !== null && resp.ok !== false) {
      mensaje = resp.message;
    }
    if (resp.msgusu !== undefined && resp.msgusu !== null) {
      mensaje = resp.msgusu;
    }
    this.loading=false;
    //this.msgs = null;
    //this.msgs.push({ severity: 'error', summary: mensaje, detail: '' });
    this.mostrarError(mensaje);
  }

  /**Fija mensaje de respuesta resultado de ejecucion de una peticion al core. */
  public mostrarMensaje(msgs: string, persistirmsg = false, acumular = false) {
    if (!acumular) {
      //   this.msgs = msgs;
    } else {
      //this.msgs = this.msgs.concat(msgs);
    }
    // this.msgs = msgs;
    //Swal.fire('Excelente!', msgs, 'success');
    this.notifier.notify( 'success', msgs );
  }
  public mostrarDialogo(msgs: string, persistirmsg = false, acumular = false) {
    Swal.fire('Excelente!', msgs, 'success');    
  }
  public mostrarDialogoError(msgs: string, persistirmsg = false, acumular = false) {
    Swal.fire('Ah ocurrido un error!', msgs, 'error');    
  }
  public mostrarError(msgs: string, persistirmsg = false, acumular = false) {
    if (!acumular) {
      //   this.msgs = msgs;
    } else {
      //this.msgs = this.msgs.concat(msgs);
    }
    // this.msgs = msgs;
    //Swal.fire('Algo pasó!', msgs, 'error');
    this.notifier.notify('error',msgs);
  }
  public mostrarInformacion(msgs: string, persistirmsg = false, acumular = false) {
    if (!acumular) {
      //   this.msgs = msgs;
    } else {
      //this.msgs = this.msgs.concat(msgs);
    }
    // this.msgs = msgs;
    //Swal.fire('Información', msgs, 'info');
    this.notifier.notify('info',msgs);
    this.loading=false;
  }
  public mostrarAlerta(msgs: string, persistirmsg = false, acumular = false) {
    if (!acumular) {
      //   this.msgs = msgs;
    } else {
      //this.msgs = this.msgs.concat(msgs);
    }
    // this.msgs = msgs;
  //  Swal.fire('Información', msgs, 'warning');
    this.notifier.notify('warning',msgs);
    
  }


}
