// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  apiDomain:"https://gestionadministrativadircom.com/WebApiCore",
 //apiDomain:"http://localhost:65511",
   url: "http://intelger-001-site6.atempurl.com",
  apiPort: "",
  keyGoogleMaps: 'AIzaSyDxD70We8cpkA2y9EfMGG1tTZMIHrkNZ6g',
  firebaseConfig: {
    apiKey: "AIzaSyD4dYSugwRbVoKG8600UKp9sXS0MMjvWIA",
    authDomain: "todope-5bc5f.firebaseapp.com",
    projectId: "todope-5bc5f",
    storageBucket: "todope-5bc5f.appspot.com",
    messagingSenderId: "888339988191",
    appId: "1:888339988191:web:efda4379698d40b0a8336a",
    measurementId: "G-Z9NH1WDQ47"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
