import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DtoServicios } from './util/servicios/dto.servicios';
import { AppService } from './util/servicios/app.service';
import { NotifierService } from 'angular-notifier';
import { environment } from '../environments/environment';
import * as signalR from "@aspnet/signalr";
import { NotificacionService } from './util/servicios/notification.service';
import { HttpClient } from '@angular/common/http';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})



export class AppComponent implements OnInit, AfterViewInit {
  isCondensed = true;
  version: string;
  constructor(private router: Router, public dtoServicios: DtoServicios, public appService: AppService, notifier: NotifierService
    , public signalRService: NotificacionService, private swUpdate: SwUpdate

  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove('sidebar-enable');
      }
    });

  }

  async ngOnInit() {

    if (environment.production === true) {
      if (location.href.includes("http:")) {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    const { version } = await import('../../package.json');
    this.version = version;
    let a=0;
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('Nueva versión disponible, cargar nueva versión?')) {
          window.location.reload();
        }
      });
      this.swUpdate.checkForUpdate();
    }
    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.classList.remove('sidebar-enable');
    document.body.removeAttribute('data-sidebar-size');

    this.appService.login = false;
    this.appService.validarotp = sessionStorage.getItem('validarotp') === 'true';
    if (this.appService.validarotp === true) {
      sessionStorage.clear();
    }
    if (sessionStorage.getItem('mradicacion')) {
      this.appService.titulopagina = this.appService.titulopagina + JSON.parse(sessionStorage.getItem('mradicacion')).np;
    }
    if (sessionStorage.getItem('c') && !this.appService.validarotp) {
      this.appService.login = true;
      const mradicacion = JSON.parse(sessionStorage.getItem('mradicacion'));
      this.inicializarAmbiente(mradicacion);
      //this.logincomponent.validaCambioPassword(mradicacion);
    } else {
      //  this.router.navigate([""]);  
    }
    this.appService.dashboard = true;

    this.onToggleMobileMenu();
  }
  private inicializarAmbiente(mradicacion: any) {
    //  cia^suc^ofi^usr^perfil^idioma^canal^ipaddress
    let c: string;
    const ip = document.domain === 'localhost' ? '127.0.0.1' : document.domain;
    //  Lista de roles del usuario.
    this.appService.llenarRolesUsuario(mradicacion.roles);
    c = mradicacion.cc + '^' + mradicacion.cu + '^';
    c = c + mradicacion.roles[0].id + '^' + mradicacion.ci + '^' + mradicacion.cca;
    sessionStorage.setItem('c', c);
    sessionStorage.setItem('mradicacion', JSON.stringify(mradicacion));
    // ejecuta consulta del menu del primer rol
    this.appService.consultarMenu();
    // Fija datos de radicacion del usuario en el singleton de servicios.
    this.dtoServicios.actualizarRadicacion(mradicacion);
    this.iniciarNotificaciones();
    this.router.navigate(["dashboard"]);
    // Nombre de la persona
    // this.appService.npersona = mradicacion.np;
  }
  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }
  public iniciarNotificaciones() {
    this.signalRService.startConnection(this.dtoServicios.mradicacion.cusuario);
    this.signalRService.addTransferDataListener();

  }
  public estaVacio(obj: any): boolean {
    if (obj === undefined || obj === null || obj === '' || (typeof obj === 'object' && !(obj instanceof Date) && Object.keys(obj).length === 0) || obj.length <= 0) {
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
  }
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');
    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }

}
