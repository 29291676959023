<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <ul class="nav nav-fill text-center">
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
                <li class="nav-item">
                  <p class="nav-link">Todos los derechos reservados.  2023</p>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Políticas de privacidad</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Términos y condiciones</a>
                </li>
                
              </ul>
        </div>
    </div>
</footer>
