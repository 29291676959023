import {Injectable} from '@angular/core';
import {Routes, Router, ActivatedRoute, RouterModule, provideRoutes} from '@angular/router';
import {DtoServicios} from './dto.servicios';

import {VerificaAutorizacionService} from '../../util/servicios/verificaAutorizacion.service';

import { MenuItem } from '../interfaces/MenuItem';
import { NotificacionService } from './notification.service';

@Injectable()
export class AppService {
  public modelo: any = {};
  /**Objeto que contine mensajes aplicativos. */
  /**Menu asociado a un rol del usuario.*/
  lmenu: any = [];
  /**false, indica que el usuario no ingresa a la aplicacion, true el usuario esta en la aplicacion y se muestra el menu.*/
  public login = false;

  /**Varible que valida la la clave del otp*/
  public validarotp = true;
  /**Variable que valida el olvido contrasenia*/
  public validarclavetemp = false;
  /**Varible que valida la la clave del otp*/
  public cambiopassword = false;
  public dashboard=false;
  

  /**Varible de referencia al componente de cambio de password*/
  //public cambiocontraseniacomponent: CambioContraseniaLoginComponent;

  /**Lista de roles del usuario.*/
  public lrolesusuario: any[] = [{ label: '...', value: null }];
  public lmodulosusuario: any[] = [{ label: '...', value: null }];

  public menutransaccion: any =[];
  public lmodulosusuariod: any = [];
  /**Codigo de rol activo*/
  public crol: any;
  /**Nombre de rol activo*/
  public nrol: any;

  public cmodulo: any;
  /**Nombre de rol activo*/
  public nmodulo: any;

  /** Menu asociado al rol */
  //menu: MenuItem[];
  
  menu :MenuItem[];;

  muestraRoles = false;

  muestraAyuda = false;

  muestraUserInfo = false;

  public titulopagina = '';

  /**Rutas por defecto**/
  public appRutasDefecto: Routes = [
    //  { path: '', component: AnalyticsComponent, canActivate: [VerificaAutorizacionService] },
    // { path: 'reload', component: ReloadComponent },
//    { path: '**', redirectTo: '' },
  ];


  constructor(public router: Router, public dtoServicios: DtoServicios, public signalRService: NotificacionService ) { }

  /**Metodo que se ejecuta en el bootstrap de la aplicacion, o al salir de la aplicacion. */
  public salir(event = null): void {
   // this.msgs = null;
    this.modelo = {};
    if (event !== null && event !== undefined) {
      event.preventDefault();
    }
    this.dtoServicios.logout();
    this.login = false;
  }
  public estaVacio(obj: any): boolean {
    if (obj === undefined || obj === null || obj === '' || (typeof obj === 'object' && !(obj instanceof Date) && Object.keys(obj).length === 0) || obj.length <= 0) {


      return true;
    }
    return false;
  }
  /**Metodo que se ejecuta en el bootstrap de la aplicacion, o dar la ruta hacia cambio de password. */
  public cambioPassword(event = null): void {
   // this.msgs=null;

    if (event !== null && event !== undefined) {
      event.preventDefault();
    }

    sessionStorage.setItem('m', '2');
    sessionStorage.setItem('t', '10');
    sessionStorage.setItem('titulo', '2-10 Cambio De Constraseña');
    sessionStorage.setItem('ins', 'true');
    sessionStorage.setItem('upd', 'true');
    sessionStorage.setItem('del', 'false');
    sessionStorage.setItem('ac', 'false');
    sessionStorage.setItem('path', '2-10');

    this.router.navigate(['2-10'], { skipLocationChange: true });
    this.titulopagina = '2-10 Cambio De Constraseña';
  }

  public cargaPaginaInicial(reg): void {
    setTimeout(() => {
      const transaccion=reg.split("-");
      if(transaccion.length>1)
      sessionStorage.setItem('m', transaccion[0]);
      sessionStorage.setItem('t', transaccion[1]);
      sessionStorage.setItem('titulo', 'Inicio');
      sessionStorage.setItem('ins', 'true');
      sessionStorage.setItem('upd', 'true');
      sessionStorage.setItem('del', 'false');
      sessionStorage.setItem('ac', 'false');
      sessionStorage.setItem('path', reg);

      this.router.navigate([reg], { skipLocationChange: true });
      //this.titulopagina = '3-2 PRESUPUESTO';
    }, 500);
  }

  public cambioClave(): void {
    setTimeout(() => {
      sessionStorage.setItem('m', '2');
      sessionStorage.setItem('t', '9');
      sessionStorage.setItem('titulo', ' Cambio De Constraseña');
      sessionStorage.setItem('ins', 'true');
      sessionStorage.setItem('upd', 'true');
      sessionStorage.setItem('del', 'false');
      sessionStorage.setItem('ac', 'false');
      sessionStorage.setItem('path', '2-9');
      this.router.navigate(['2-9'], { skipLocationChange: true });
      this.titulopagina = '2-9 Cambio De Constraseña';
    }, 500);
  }

  // Lista de datos roles de usuario a utlizar en la pagina.
  public llenarRolesUsuario(lroles: any): void {


    this.lrolesusuario = [];
    for (const i in lroles) {
      if (lroles.hasOwnProperty(i)) {
        const reg = lroles[i];
        this.lrolesusuario.push({ label: reg.nombre, value: reg.crol });
      }
    }
    if (this.lrolesusuario != null && this.lrolesusuario.length > 0) {
      this.crol = this.lrolesusuario[0].value;
      this.nrol = this.lrolesusuario[0].label;
    }
  }

  public llenarModulosUsuario(lmodulos: any): void {
    this.lmodulosusuariod = lmodulos;
    this.lmodulosusuario = [];
    for (const i in lmodulos) {
      if (lmodulos.hasOwnProperty(i)) {
        const reg = lmodulos[i];
        this.lmodulosusuario.push({ label: reg.nombre, value: reg.cmodulo });
      }
    }
    if (this.lmodulosusuario != null && this.lmodulosusuario.length > 0) {
      this.cmodulo = this.lmodulosusuario[0].value;
      this.nmodulo = this.lmodulosusuario[0].label;
    }
  }

  /**Invoca al core para realizar login de la aplicacion. */
  public consultarMenu() {
    //this.msgs =null;
    const rqMantenimiento: any = new Object();
    rqMantenimiento.cmodulo = '1';
    rqMantenimiento.ctransaccion = 'menu';
    rqMantenimiento.ccanal="TCL";
    rqMantenimiento.Mdatos= {};
    rqMantenimiento.Mdatos.crol=this.crol;
    
    this.dtoServicios.ejecutarRestMantenimiento(rqMantenimiento)
      .subscribe(
        resp => {
          this.manejaRespuestaMenu(resp);
          // if (iscargapagina) {
          //   this.cargaPaginaAfterMenu();
          // }
        },
        error => {
          this.dtoServicios.manejoError(error);
        });
  }
  random(i: number, f: number) {
    return Math.floor((Math.random() * f) + i);
  }
  cautoriza:any;
  public generarCodigoTmp() {
    //this.msgs =null;
    this.cautoriza = this.random(100000, 9999999) + "AUTH";
   
    const rqMantenimiento: any = new Object();
    rqMantenimiento.cmodulo = '2';
    rqMantenimiento.ctransaccion = '10';
    rqMantenimiento.ccanal="TCL";
    rqMantenimiento.Mdatos= {};
    rqMantenimiento.Mdatos.cautoriza=this.cautoriza;
    rqMantenimiento.Mdatos.generar=true;
    
    
    this.dtoServicios.ejecutarRestMantenimiento(rqMantenimiento)
      .subscribe(
        resp => {
          this.manejaRespuestaMenu(resp);
          // if (iscargapagina) {
          //   this.cargaPaginaAfterMenu();
          // }
        },
        error => {
          this.dtoServicios.manejoError(error);
        });
  }
  /**Manejo respuesta de ejecucion de login. */
  private manejaRespuestaMenu(resp: any) {
    //this.msgs =null;
    if (resp.cod === 'OK') {
      this.menu = resp.menu;
      this.signalRService.ndata=resp.nnotificaciones;
      this.signalRService.data=resp.notificaciones;    
      //this.menutransaccion = resp.menutransaccion
      //this.llenarModulosUsuario(resp.lmodulos);
      this.dtoServicios.cpresupuesto=resp.cpresupuesto;
      this.login = true;
      this.cargaPaginaInicial(resp.inicio);
      //for (const i in resp.rutas) {
       // if (resp.rutas.hasOwnProperty(i)) {
     //     resp.rutas[i].canActivate[0] = VerificaAutorizacionService;
      //  }
     // }
      for (const i in this.appRutasDefecto) {
        if (this.appRutasDefecto.hasOwnProperty(i)) {
       //   resp.rutas.push(this.appRutasDefecto[i]);
        }
      }
      //while (this.router.config.length > 0) {
      //  this.router.config.pop();
   //   }
      //this.router.resetConfig(resp.rutas);
    }
    if (resp.cod !== 'OK') {
      let msg = '';
      msg = resp.cod !== undefined ? msg = msg + resp.cod + ' ' : msg + ' ';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      this.dtoServicios.llenarMensaje(resp, false);
    }
  }

  /** Regenera el objeto que tiene la radicacion del usuario. */
  public fijarRadicacion(mradicacion: any) {
    //    let c: string;
    //    c = mradicacion.cc + '^' + mradicacion.cs + '^' + mradicacion.cag + '^' + mradicacion.cu + '^';
    //    c = c + this.crol + '^' + mradicacion.ci + '^' + mradicacion.cca;
    //    sessionStorage.setItem('c', c);
    sessionStorage.setItem('mradicacion', JSON.stringify(mradicacion));
  }

  public cargarRol(item: any) {
    this.crol = item.value;
    this.nrol = item.label;
    const mradicacion = JSON.parse(sessionStorage.getItem('mradicacion'));
    this.fijarRadicacion(mradicacion);
    this.consultarMenu();
    this.mostrarRoles();
  }
  public cargarAyuda(item: any) {
    this.cmodulo = item.value;
    this.nmodulo = item.label;
    const mradicacion = JSON.parse(sessionStorage.getItem('mradicacion'));
    this.fijarRadicacion(mradicacion);
    for (const i in this.lmodulosusuariod) {
      if (this.lmodulosusuariod.hasOwnProperty(i)) {
        const reg = this.lmodulosusuariod[i];
        if (reg.cmodulo === this.cmodulo) {
          window.open(reg.rutaayuda);

        }
      }
    }
    this.muestraAyuda = !this.muestraAyuda;
  }

  public mostrarRoles() {
    this.muestraRoles = !this.muestraRoles;
  }
  public mostrarAyuda() {
    this.muestraAyuda = !this.muestraAyuda;
  }
}


