<ngx-loading [show]="!estaVacio(dtoServicios.loading) && dtoServicios.loading" [config]="{
  backdropBorderRadius: '14px',
  backdropBackgroundColour: 'rgba(0,0,0,0.87)',
  fullScreenBackdrop: 'true',
  primaryColour: '#fff',
  secondaryColour: '#000',
  tertiaryColour: '#e2f000'
}">
</ngx-loading>

<notifier-container></notifier-container>
<div *ngIf="appService.login && !appService.validarotp && appService.dashboard" class="layout-main">

  <div id="layout-wrapper">

    <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
    </app-topbar>

    <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

    <div class="main-content">
      <div class="page-content">
        <!-- content -->
        <router-outlet></router-outlet>
      </div>

      <!-- footer -->

    </div>
  </div>
  
  <app-footer></app-footer>
  
</div>
<router-outlet></router-outlet>

