import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppService } from 'src/app/util/servicios/app.service';
import { DtoServicios } from 'src/app/util/servicios/dto.servicios';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  
  constructor(private router: Router, public dtoServicios: DtoServicios, public appService: AppService, notifier: NotifierService) {
    if (!sessionStorage.getItem('mradicacion')) {
      this.router.navigate([""]);
    }
    
  }
  ngOnInit() {
    
  }
}