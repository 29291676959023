import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { register } from 'register-service-worker';
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

register('/sw.js', {
  ready(registration) {
    console.log('Service worker is active');
  },
  registered(registration) {
    console.log('Service worker has been registered');
  },
  cached(registration) {
    console.log('Content has been cached for offline use');
  },
  updatefound(registration) {

   

  },
  updated(registration) {
    const worker = registration.waiting;
    if (worker) {
      worker.postMessage({ action: 'skipWaiting' });
    }
   
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(err) {
    console.error('Error during service worker registration:', err);
  }
});