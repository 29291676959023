import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { DefaultComponent } from './pages/dashboards/default/default.component'

const routes: Routes = [
  {
    path: 'dashboard',
    component: DefaultComponent
  },
  {
    path: '',
    // component: DefaultComponent,
    //loadChildren: () => import('./pages/landingpage/landingpage.module').then(m => m.LandingPageModule)
    loadChildren: () => import('./modulos/seguridad/account/auth/admin/login.module').then(m => m.LoginModule)
  },


  { path: '1-1', loadChildren: () => import('./modulos/generales/catalogos/compania/compania.module').then(m => m.CompaniaModule) },
  { path: '1-2', loadChildren: () => import('./modulos/generales/catalogos/canal/canal.module').then(m => m.CanalModule) },
  { path: '1-3', loadChildren: () => import('./modulos/generales/catalogos/modulo/modulo.module').then(m => m.ModuloModule) },
  { path: '1-4', loadChildren: () => import('./modulos/generales/catalogos/elemento/elemento.module').then(m => m.ElementoModule) },
  { path: '1-5', loadChildren: () => import('./modulos/generales/catalogos/secuencia/secuencia.module').then(m => m.SecuenciaModule) },
  { path: '1-6', loadChildren: () => import('./modulos/generales/catalogos/transaccion/transaccion.module').then(m => m.TransaccionModule) },
  { path: '1-7', loadChildren: () => import('./modulos/generales/catalogos/catalogo/catalogo.module').then(m => m.CatalogoModule) },
  { path: '1-8', loadChildren: () => import('./modulos/generales/catalogos/catalogodetalle/catalogodetalle.module').then(m => m.CatalogoComponentModule) },
  { path: '1-17', loadChildren: () => import('./modulos/generales/catalogos/parametros/parametros.module').then(m => m.ParametrosModule) },
  //Seguridad
  { path: '2-1', loadChildren: () => import('./modulos/seguridad/catalogos/rol/rol.module').then(m => m.RolModule) },
  { path: '2-2', loadChildren: () => import('./modulos/seguridad/catalogos/menu/menu.module').then(m => m.MenuModule) },
  { path: '2-3', loadChildren: () => import('./modulos/seguridad/catalogos/menu/menu.module').then(m => m.MenuModule) },
  { path: '2-4', loadChildren: () => import('./modulos/seguridad/account/registro/registro.module').then(m => m.RegistronModule) },
  { path: '2-5', loadChildren: () => import('./modulos/seguridad/catalogos/auditoriageneral/auditoriageneral.module').then(m => m.AuditoriageneralModule) },
  { path: '2-6', loadChildren: () => import('./modulos/seguridad/catalogos/auditoria/auditoria.module').then(m => m.AuditoriaModule) },
  { path: '2-7', loadChildren: () => import('./modulos/seguridad/account/perfil/perfil.module').then(m => m.PerfilModule) },
  { path: '2-8', loadChildren: () => import('./modulos/seguridad/account/auth/passwordchange/passwordchange.module').then(m => m.PasswordChangeModule) },
  { path: '2-9', loadChildren: () => import('./modulos/seguridad/cambioPassword/passwordchange.module').then(m => m.PasswordChangeModule) },
  { path: '2-11', loadChildren: () => import('./modulos/seguridad/catalogos/rolusuario/rolusuario.module').then(m => m.RolUsuarioModule) },

  //pages
  { path: 'login', loadChildren: () => import('./modulos/seguridad/account/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'resetPassword', loadChildren: () => import('./modulos/seguridad/account/auth/passwordreset/passwordreset.module').then(m => m.PasswordresetModule) },
  { path: 'activation-code', loadChildren: () => import('./modulos/seguridad/account/auth/otp/otp.module').then(m => m.OtpModule) },
  { path: 'reset-code', loadChildren: () => import('./modulos/seguridad/account/auth/otpreset/otpreset.module').then(m => m.OtpResetModule) },

  { path: '3-1', loadChildren: () => import('./modulos/presupuesto/catalogos/proveedores/proveedores.module').then(m => m.ProveedoresModule) },
  { path: '3-2', loadChildren: () => import('./modulos/presupuesto/presupuesto/presupuesto/presupuesto.module').then(m => m.PresupuestoModule) },
  { path: '3-3', loadChildren: () => import('./modulos/presupuesto/catalogos/dimensiones/dimensiones.module').then(m => m.DimensionesModule) },
  { path: '3-4', loadChildren: () => import('./modulos/presupuesto/catalogos/extenciones/extenciones.module').then(m => m.ExtencionesModule) },
  { path: '3-5', loadChildren: () => import('./modulos/presupuesto/catalogos/librodiario/librodiario.module').then(m => m.LibrodiarioModule) },
  { path: '3-6', loadChildren: () => import('./modulos/presupuesto/catalogos/medios/medios.module').then(m => m.MediosModule) },
  { path: '3-7', loadChildren: () => import('./modulos/presupuesto/catalogos/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  { path: '3-8', loadChildren: () => import('./modulos/presupuesto/catalogos/estados/estados.module').then(m => m.EstadosModule) },
  { path: '3-9', loadChildren: () => import('./modulos/presupuesto/catalogos/flujo/flujo.module').then(m => m.FlujoModule) },
  { path: '3-10', loadChildren: () => import('./modulos/presupuesto/catalogos/gerencia/gerencia.module').then(m => m.GerenciaModule) },
  { path: '3-11', loadChildren: () => import('./modulos/presupuesto/catalogos/coordinacion/coordinacion.module').then(m => m.CoordinacionModule) },
  { path: '3-12', loadChildren: () => import('./modulos/presupuesto/catalogos/tipoaccion/tipoaccion.module').then(m => m.TipoAccionModule) },
  { path: '3-13', loadChildren: () => import('./modulos/presupuesto/catalogos/acciones/acciones.module').then(m => m.AccionesModule) },
  { path: '3-14', loadChildren: () => import('./modulos/presupuesto/catalogos/empleados/empleados.module').then(m => m.EmpleadosModule) },
  { path: '3-15', loadChildren: () => import('./modulos/presupuesto/catalogos/acciones/acciones.module').then(m => m.AccionesModule) },

  { path: '3-20', loadChildren: () => import('./modulos/presupuesto/consultas/libro/libro.module').then(m => m.LibroModule) },
  { path: '3-21', loadChildren: () => import('./modulos/presupuesto/consultas/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  //CONSULTA PARA GERENTES
  { path: '3-22', loadChildren: () => import('./modulos/presupuesto/consultas/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },

  //CONSULTA TODOS
  { path: '3-23', loadChildren: () => import('./modulos/presupuesto/consultas/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  //CONSULTA TODOS
  { path: '3-28', loadChildren: () => import('./modulos/presupuesto/consultas/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  
  //consultas firmas
  { path: '3-24', loadChildren: () => import('./modulos/presupuesto/validaciones/libro/libro.module').then(m => m.LibroModule) },
  { path: '3-25', loadChildren: () => import('./modulos/presupuesto/validaciones/financiero/libro.module').then(m => m.LibroModule) },
  { path: '3-26', loadChildren: () => import('./modulos/presupuesto/validaciones/libro/libro.module').then(m => m.LibroModule) },
  { path: '3-27', loadChildren: () => import('./modulos/presupuesto/consultas/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  //
  { path: '3-40', loadChildren: () => import('./modulos/presupuesto/catalogos/empleadosdimension/empleadosdimension.module').then(m => m.EmpleadosDimensionModule) },

  //Aprobaciones Requerimientos
  { path: '3-98', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },

  { path: '3-99', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  { path: '3-100', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  { path: '3-101', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  { path: '3-102', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  //REQUERIMIENTO PARA GERENTES
  { path: '3-103', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  //REQUERIMIENTO PARA ASISTENTE ADMINISTRATIVA
  { path: '3-104', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
  //LIBRO DIARIO 
  { path: '3-105', loadChildren: () => import('./modulos/presupuesto/catalogos/librodiario/librodiario.module').then(m => m.LibrodiarioModule) },
 //REQUERIMIENTO PARA EJECUTIVOS
 { path: '3-106', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
 //REQUERIMIENTO PARA DIRECCIÓN FINACIERA
 
 { path: '3-107', loadChildren: () => import('./modulos/presupuesto/aprobacion/requerimientos/requerimientos.module').then(m => m.RequerimientoModule) },
 
 { path: '3-120', loadChildren: () => import('./modulos/presupuesto/catalogos/ejecutivos/ejecutivos.module').then(m => m.EjecutivosModule) },
 
  //Aprobaciones libro diario
  { path: '3-200', loadChildren: () => import('./modulos/presupuesto/aprobacion/librodiario/librodiario.module').then(m => m.LibrodiarioModule) },
  { path: '3-201', loadChildren: () => import('./modulos/presupuesto/aprobacion/librodiario/librodiario.module').then(m => m.LibrodiarioModule) },
  { path: '3-202', loadChildren: () => import('./modulos/presupuesto/aprobacion/librodiario/librodiario.module').then(m => m.LibrodiarioModule) },
  { path: '3-203', loadChildren: () => import('./modulos/presupuesto/aprobacion/librodiario/librodiario.module').then(m => m.LibrodiarioModule) },
  { path: '3-204', loadChildren: () => import('./modulos/presupuesto/aprobacion/librodiario/librodiario.module').then(m => m.LibrodiarioModule) },
  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
// canActivate: [AuthGuard]

