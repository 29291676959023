import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    config: any = { 'host': 'http://localhost', 'port': '64496', 'context': '/api' };
    public urlConsultar = '';
    public urlMantener = '';
    public urlLogin = '';
    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.urlLogin = this.urlLogin + this.config.host + ':' + this.config.port + '' + this.config.context + '/login';
   
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        const rq = JSON.stringify( { email, password });
        return this.http.post(this.urlLogin+'/authenticate',rq,{headers : new HttpHeaders({ 'Content-Type': 'application/json' })})
        .pipe(map(resp=> {
             let r:any;
             r=resp;
            
            if (r.user && r.token) {
                r.user.token=r.token;
                r.user.password=null;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(r.user));
                this.currentUserSubject.next(r.user);
            }
            return r.user;
      
           
          }));
      

        }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
