import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import * as signalR from "@aspnet/signalr";
import { DtoServicios } from "./dto.servicios";
import { Howl } from "howler";
@Injectable({
  providedIn: "root",
})
export class NotificacionService {
  public data: any = [];
  public chart:any={series:{data:[]},xaxis:{categories:[]}};
  public ndata = 0;
  public pagina = 1;
  private hubConnection: signalR.HubConnection;
  public startConnection = (userId) => {
    this.data = [];
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiDomain + "/NotificationUserHub?userId=" + userId)
      .build();
    this.hubConnection
      .start()
      .then(() => {
        this.hubConnection
          .invoke("GetConnectionId")
          .then((resp) => {});
      })
      .catch((err) => {});
  };
  public addTransferDataListener = () => {
    this.hubConnection.on("sendToUser", (data) => {
      this.procesarNotificacion(data);
    });
  };
  public addTransferChartDataListener = () => {
    this.hubConnection.on('transferchartdata', (data) => {
      this.chart = data;
     
    });
  }
  registroSeleccionado: any;
  public actualizar(registro) {
    this.registroSeleccionado = registro;
    const index = this.data.findIndex(
      (n) => n.cmensaje === this.registroSeleccionado.cmensaje
    );
    if (index >= 0) {
      this.data[index] = registro;
    } else {
      //this.mostrarMensajeWarn('REGISTRO NO ACTUALIZADO');
    }
  }
  private procesarNotificacion(data: any) {
    this.data.unshift(data);
    var sound = new Howl({
      src: ["/assets/sounds/notificacion.mp3"],
    });

    sound.play();
    this.ndata++;
    this.notifyMe(data);
  }
  notifyMe(data) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
    }
    

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(data.titulo, {
        body: data.mensaje,
        icon: (data.mdatos.foto)?data.mdatos.foto:'assets/images/users/avatar.png',
      });
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {

      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification(data.titulo, {
            body: data.mensaje,
            icon: (data.mdatos.foto)?data.mdatos.foto:'assets/images/users/avatar.png',
          });
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }
}
