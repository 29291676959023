import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/util/servicios/app.service";
import { DtoServicios } from "src/app/util/servicios/dto.servicios";
import { NotificacionService } from "src/app/util/servicios/notification.service";
@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  public currentTime: any;
  constructor(
    public dtoServicios: DtoServicios,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public appService: AppService,

    public signalRService: NotificacionService,
    // tslint:disable-next-line: variable-name
    public _cookiesService: CookieService
  ) { }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  listMenu = [
    { text: "Dimensiones", op: "3-3", tr: "3" },
    { text: "Extensiones", op: "3-4", tr: "4" },
    { text: "Libro Diario", op: "3-5", tr: "5" },
    { text: "Medios", op: "3-6", tr: "6" },
    { text: "Requerimiento", op: "3-7", tr: "7" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  getMinimalisticRelativeTime(dateTime) {
    if (!dateTime) {
      return null;
    }
    let difference = Math.abs(
      new Date().getTime() - new Date(dateTime).getTime()
    );

    var seconds = difference / 1000;
    var minutes = difference / 1000 / 60;
    var hours = minutes / 60;
    var days = hours / 24;
    var months = days / 30;
    var years = months / 12;
    let resp = 'Hace ';
    if (Math.trunc(years) > 0) {
      return (resp + Math.trunc(years) + ((Math.trunc(years) == 1)
        ? ' año'
        : ' años'));
    } else if (Math.trunc(months) > 0) {
      return (resp + Math.trunc(months) + ((Math.trunc(months) == 1)
        ? ' mes'
        : ' meses'));
    } else if (Math.trunc(days) > 0) {
      return (resp + Math.trunc(days) + ((Math.trunc(days) == 1)
        ? ' día'
        : ' días'));
    } else if (Math.trunc(hours) > 0) {
      return (resp + Math.trunc(hours) + ((Math.trunc(hours) == 1)
        ? ' hora'
        : ' horas'));
    } else if (Math.trunc(minutes) > 0) {
      return (resp + Math.trunc(minutes) + ((Math.trunc(minutes) == 1)
        ? ' minuto'
        : ' minutos'));
    } else {
      return 'un momento';
    }
  }
  version:any="";
 async ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };
    this.cookieValue = this._cookiesService.get("op");
    const { version } = await import('../../../../package.json');
    this.version = version;
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }
  setMenu(text: string, op: string, tr: string) {
    this.countryName = text;
    this.cookieValue = op;
    setTimeout(() => {
      sessionStorage.setItem("m", "3");
      sessionStorage.setItem("t", tr);
      sessionStorage.setItem("titulo", text);
      sessionStorage.setItem("ins", (this.dtoServicios.estado) ? "true" : "false");
      sessionStorage.setItem("upd", "true");
      sessionStorage.setItem("del", "true");
      sessionStorage.setItem("ac", "true");
      let path = "3-" + tr;
      sessionStorage.setItem("path", path);
      this.router.navigate([path], {
        skipLocationChange: true,
        queryParams: null
      });
    }, 500);

  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  cambiarRol(rol: any) {
    this.appService.crol = rol.value;
    this.appService.consultarMenu();
  }
  logout() {
    this.appService.dtoServicios.logout();
  }

  /**
   * Fullscreen method
   */
  getNotificaciones() {
    if (!this.signalRService.data) {
      return "";
    }
    return this.signalRService.data.length;
  }
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  onReachEnd() {
    let reg: any = {};
    reg.cusuario = this.dtoServicios.mradicacion.cusuario;
    reg.pagina = this.signalRService.pagina + 1;
    this.dtoServicios
      .ejecutarServicoNotificacion(reg, "/Notifications/notificationresult")
      .subscribe(
        (resp) => {
          this.manejaRespuestaNotificacion(resp);
        },
        (error) => {
          this.dtoServicios.manejoError(error);
        }
      );
  }
  notificacionVisitada(registro: any) {
    if (this.dtoServicios.estaVacio(registro.flectura)) {
      let reg: any = {};
      reg.cmensaje = registro.cmensaje;
      this.dtoServicios
        .ejecutarServicoNotificacion(reg, "/Notifications/notificationvisit")
        .subscribe(
          (resp) => {
            this.manejarRespuestaVisita(resp);
          },

          (error) => {
            this.dtoServicios.manejoError(error);
          }
        );
    } else {
      this.abrirPagina(registro);
    }
  }

  manejarRespuestaVisita(resp) {
    if (resp.cod === "OK") {
      if (!this.dtoServicios.estaVacio(resp.nnotificaciones)) {
        this.signalRService.actualizar(resp.nnotificaciones);
        this.abrirPagina(resp.nnotificaciones);
      }
    }
  }

  borrar() {
    this.signalRService.ndata = 0;
  }
  manejaRespuestaNotificacion(resp) {
    let registros = 0;

    if (resp.cod === "OK") {
      this.signalRService.pagina = this.signalRService.pagina + 1;
      if (resp.notificaciones) {
        for (const i in resp.notificaciones) {
          if (resp.notificaciones.hasOwnProperty(i)) {
            const reg = resp.notificaciones[i];
            this.signalRService.data.push(reg);
            registros++;
          }
        }
      }
      if (registros > 5) this.signalRService.pagina + 1;
    }
  }
  public cargarCambio(): void {
    setTimeout(() => {
      sessionStorage.setItem('m', '2');
      sessionStorage.setItem('t', '9');
      sessionStorage.setItem('titulo', ' Cambio De Constraseña');
      sessionStorage.setItem('ins', 'true');
      sessionStorage.setItem('upd', 'true');
      sessionStorage.setItem('del', 'false');
      sessionStorage.setItem('ac', 'false');
      sessionStorage.setItem('path', '2-9');

      this.router.navigate(['2-9'], { skipLocationChange: true });      
    }, 500);
  }
  abrirPagina(reg: any) {
    setTimeout(() => {
      sessionStorage.setItem("m", reg.cmodulo + "");
      sessionStorage.setItem("t", reg.ctransaccion);
      sessionStorage.setItem("titulo", "");
      sessionStorage.setItem("ins", "true");
      sessionStorage.setItem("upd", "true");
      sessionStorage.setItem("del", "true");
      sessionStorage.setItem("ac", "true");
      let path = reg.cmodulo + "-" + reg.ctransaccion;
      sessionStorage.setItem("path", path);
      let params = JSON.parse(reg.parametros);
      this.router.navigate([path], {
        skipLocationChange: true,
        queryParams: params,
      });
    }, 500);
  }

  public cargaPerfil(): void {
    setTimeout(() => {
      sessionStorage.setItem("m", "2");
      sessionStorage.setItem("t", "7");
      sessionStorage.setItem("titulo", "Perfil");
      sessionStorage.setItem("ins", "false");
      sessionStorage.setItem("upd", "false");
      sessionStorage.setItem("del", "false");
      sessionStorage.setItem("ac", "false");
      sessionStorage.setItem("path", "2-7");

      this.router.navigate(["2-7"], { skipLocationChange: true });
    }, 500);
  }
}
